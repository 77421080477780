import React, { useEffect, useState } from 'react'
import { Trans, useTranslation }      from 'react-i18next'
import {
  Box,
  Button, Checkbox,
  Container, FormControl,
  FormControlLabel, FormGroup,
  Grid, Input, InputLabel, Link,
  Paper, Stack, TextField, Typography,
}                                                     from '@mui/material'
import { useApi }                                     from '../utils/api'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
import { useSnackbar }                                from 'notistack'
import ShortFormControl                               from '../ui/components/short-form-control'
import AlertSimpleDialog                              from '../ui/components/alert-simple-dialog'
import BaseTable, { BaseSearch }                      from '../ui/components/base-table'
import Iconify                                        from '../ui/components/Iconify'
import { BaseBreadcrumbs }                            from '../ui/components/BaseBreadcrumbs'
import Page                                           from '../ui/components/Page'
import Consts                                         from '../consts'
import CopyZoneDialog                                 from './CopyZoneDialog'
import Label                                          from '../ui/components/Label'
import { LoadingButton }                              from '@mui/lab'
import { utils, writeFile }        from 'xlsx'

export default function CityPage () {

  const api = useApi()
  const navigate = useNavigate()
  const { cityId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const [values, setValues] = useState({
    name: '',
    phone: '',
    keyword: '',
    pattern: '',
    latitude: null,
    longitude: null,
    active: false,
    vatEnabled: true,
    billCodes: [],
    smsExpirationReminder: '',
    smsHelp: '',
    smsUnavailable: '',
    smsInvalidFormat: '',
    smsCityInactive: '',
    billingInfo: '',
    vat: '23',
  })
  const [shownDeleteDialog, setShownDeleteDialog] = useState(false)
  const [zonesQuery, setZonesQuery] = useState('')
  const [zonesForceReloadCounter, setZonesForceReloadCounter] = useState(0)
  const [exportingSetup, setExportingSetup] = useState(false)

  useEffect(() => {
    (async () => {
      if (!isNaN(cityId)) {
        let city = await api.city.detail(cityId).catch(() => ({}))
        setValues({
          ...city,
          vatEnabled: (city.flags & Consts.City.flagVatEnabled) !== 0,
          vat: city.vat ? city.vat * 100 : '',
        })
      }
    })()
  }, [cityId])

  const handleCloseDeleteDialog = (result) => {
    setShownDeleteDialog(false)
    if (result) {
      api.city.delete(cityId).then(() => {
        enqueueSnackbar(t('City deleted'), {
          variant: 'success'
        })
        navigate('/cities')
      }).catch(error => {
        enqueueSnackbar(t('Failed to delete city'), {
          variant: 'error'
        })
      })
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    let data = {
      ...values,
      vat: values.vat / 100,
      flags: (values.vatEnabled ? Consts.City.flagVatEnabled : 0),
    }
    try {
      let city = await api.city.save(data)
      navigate(`/cities/${city.id}`)
      enqueueSnackbar(t('City saved'), {
        variant: 'success'
      })
    } catch (e) {
      enqueueSnackbar(t('Failed to save city'), {
        variant: 'error'
      })
    }
  }

  const handleChange = (prop) => (event) => {
    handleChangeDirect(prop, event.target.value)
  }

  const handleChangeDirect = (prop, value) => {
    setValues({ ...values, [prop]: value })
  }

  const zonesColumns = [
    { field: 'id', headerName: t('ID'), width: 100, sortable: false, filterable: false },
    { field: 'keyword', headerName: t('Keyword'), flex: 1, sortable: false, filterable: false },
    {
      field: 'name', headerName: t('Title'), flex: 2, sortable: false, filterable: false, renderCell: (cell) => {
        return (
          <Link
            component={RouterLink}
            variant="body2"
            to={`/cities/${cell.row.cityId}/zone/${cell.row.id}`}
          >
            {cell.row.name}
          </Link>
        )
      }
    },
    { field: 'active', headerName: t('Active'), width: 110, sortable: false, filterable: false, renderCell: (cell) => {
        if (cell.row.active) {
          return <Label color={'success'} variant={'ghost'} endIcon={<Iconify icon={'material-symbols:check'} />}>{t('Active')}</Label>
        } else {
          return <Label color={'default'} variant={'ghost'} endIcon={<Iconify icon={'material-symbols:close'} />}>{t('Inactive')}</Label>
        }
      }},
    { field: 'pattern', headerName: t('Pattern'), flex: 4, sortable: false, filterable: false },
    { field: 'action', headerName: '', width: 100, sortable: false, filterable: false, renderCell: (cell) => {
        return <CopyZoneDialog t={t} zone={cell.row} onZoneDuplicated={(newZone) => setZonesForceReloadCounter(prev => prev + 1)} />
      }},
  ]

  const exportCitySetup = async (e) => {
    const getPrices = (zone, key) => (zone.prices[key]??[]).join(', ')
    const getAvailability = (zone, key) => zone.availability.filter(it => it.day === key).map(it => `${it.from}-${it.to}`).sort().join(', ')
    const exportZone = (zone) => {
      return {
        id: zone.id,
        name: zone.name,
        keyword: zone.keyword,
        pattern: zone.pattern,
        active: zone.active ? 'yes' : 'no',
        // units
        unit: zone.unit,
        unitDuration: zone.unitDuration,
        defaultOrderUnit: zone.defaultOrderUnit,
        // limits
        unitLimit: zone.unitLimit,
        priceLimit: zone.priceLimit,
        'resetLimitGap (minutes)': zone.cumulativeResetGapTime,
        cutAtMidnight: (zone.flags & Consts.Zone.flagCutAtMidnight) !== 0 ? 'yes' : 'no',
        divideFirstUnitToHalf: (zone.flags & Consts.Zone.flagDivideFirstUnitToHalf) !== 0 ? 'yes' : 'no',
        // presale
        presaleEnabled: (zone.flags & Consts.Zone.flagPresaleEnabled) !== 0 ? zone.presaleTime : '',
        longPresaleEnabled: (zone.flags & Consts.Zone.flagLongPresaleEnabled) !== 0 ? zone.longPresaleTime : '',
        'reminderTime (minutes)': zone.reminderTime,
        // prices
        'freeParking (minutes)': zone.freeParkingMinutes,
        'price - common': getPrices(zone, 'common'),
        'price - holiday': getPrices(zone, 'holiday'),
        'price - monday': getPrices(zone, 'monday'),
        'price - tuesday': getPrices(zone, 'tuesday'),
        'price - wednesday': getPrices(zone, 'wednesday'),
        'price - thursday': getPrices(zone, 'thursday'),
        'price - friday': getPrices(zone, 'friday'),
        'price - saturday': getPrices(zone, 'saturday'),
        'price - sunday': getPrices(zone, 'sunday'),
        // time availability
        'available - monday': getAvailability(zone, 'monday'),
        'available - tuesday': getAvailability(zone, 'tuesday'),
        'available - wednesday': getAvailability(zone, 'wednesday'),
        'available - thursday': getAvailability(zone, 'thursday'),
        'available - friday': getAvailability(zone, 'friday'),
        'available - saturday': getAvailability(zone, 'saturday'),
        'available - sunday': getAvailability(zone, 'sunday'),
        // days definitions
        holidays: zone.holidays?.join(', ') ?? '',
        unavailableDays: zone.unavailableDays?.join(', ') ?? '',
      }
    }
    setExportingSetup(() => true)
    try {
      const { data } = await api.zone.list(cityId)
      const ws = utils.json_to_sheet(data.map(zone => exportZone(zone)))
      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, "Zones")
      writeFile(wb, `${values.name}.xlsx`)
    } catch (e) {
      console.log(e)
      enqueueSnackbar(t('Failed to export city setup'), {
        variant: 'error'
      })
    } finally {
      setExportingSetup(() => false)
    }
  }

  return (
    <Page title={t('City')}>
      <Container component="main">
        <BaseBreadcrumbs key={'breadcrumb-city'}
                         title={isNaN(cityId) ? t('Create new city') : (`${t('City')} - ${values.name}`)}
                         items={[
                           { href: `/cities`, title: t('Cities') },
                         ]} />
        <Grid component={'form'} onSubmit={onSubmit} item mb={4} xs={12}>
          <Paper sx={{ p: 2, width: '100%' }}>
            <Grid container sx={{ width: '100%' }} spacing={2}>
              <Grid item xs={12} md={6}>
                <ShortFormControl
                  name={'name'}
                  title={t('Title')}
                  value={values.name}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={6} md={3}>
                <ShortFormControl
                  name={'phone'}
                  title={t('Phone')}
                  value={values.phone}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={6} md={3}>
                <ShortFormControl
                  name={'keyword'}
                  title={t('Keyword')}
                  value={values.keyword}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={12}>
                <ShortFormControl
                  name={'pattern'}
                  title={t('Pattern')}
                  value={values.pattern}
                  handleChange={handleChange}/>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <ShortFormControl
                    name={'latitude'}
                    title={t('Latitude')}
                    value={values.latitude ?? ''}
                    handleChange={handleChange}
                    inputProps={{
                      type: 'number',
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <ShortFormControl
                    name={'longitude'}
                    title={t('Longitude')}
                    value={values.longitude ?? ''}
                    handleChange={handleChange}
                    inputProps={{
                      type: 'number',
                    }}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox/>} checked={values.active} onChange={(e) => {
                    handleChangeDirect('active', e.target.checked)
                  }} label={t('Active')}/>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox/>} checked={values.vatEnabled} onChange={(e) => {
                    handleChangeDirect('vatEnabled', e.target.checked)
                  }} label={t('Vat enabled')}/>
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormGroup>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="city-vat">{t('Vat %')}</InputLabel>
                    <Input
                      id="app-access-token"
                      value={values?.vat ?? ''}
                      onChange={handleChange('vat')}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="info-billingInfo"
                    label={t('Billing info')}
                    multiline
                    rows={6}
                    variant="standard"
                    value={values.billingInfo}
                    onChange={handleChange('billingInfo')}
                    sx={{ width: '100%' }}/>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="info-message"
                    label={t('Expiration SMS')}
                    multiline
                    maxRows={4}
                    variant="standard"
                    value={values.smsExpirationReminder}
                    onChange={handleChange('smsExpirationReminder')}
                    helperText={<Stack direction={'row'} justifyContent={'space-between'}>
                      <Trans i18nKey={'sms-text-length'} values={{ length: values.smsExpirationReminder?.length ?? 0 }} />
                      <span>{t('Expiration SMS helper')}</span>
                    </Stack>}
                    sx={{ width: '100%' }}/>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="info-message"
                    label={t('Info message (HELP)')}
                    multiline
                    maxRows={4}
                    variant="standard"
                    value={values.smsHelp}
                    onChange={handleChange('smsHelp')}
                    helperText={<Trans i18nKey={'sms-text-length'} values={{ length: values.smsHelp?.length ?? 0 }} />}
                    sx={{ width: '100%' }}/>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="info-message"
                    label={t('Info message (when service is not available)')}
                    multiline
                    maxRows={4}
                    variant="standard"
                    value={values.smsUnavailable}
                    onChange={handleChange('smsUnavailable')}
                    helperText={<Trans i18nKey={'sms-text-length'} values={{ length: values.smsUnavailable?.length ?? 0 }} />}
                    sx={{ width: '100%' }}/>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="sms-invalid-format"
                    label={t('Invalid SMS format message (when SMS is not parsed correctly for the city)')}
                    multiline
                    maxRows={4}
                    variant="standard"
                    value={values.smsInvalidFormat}
                    onChange={handleChange('smsInvalidFormat')}
                    helperText={<Trans i18nKey={'sms-text-length'} values={{ length: values.smsInvalidFormat?.length ?? 0 }} />}
                    sx={{ width: '100%' }}/>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <TextField
                    id="sms-invalid-format"
                    label={t('Inactive city or zone')}
                    multiline
                    maxRows={4}
                    variant="standard"
                    value={values.smsCityInactive}
                    onChange={handleChange('smsCityInactive')}
                    helperText={<Trans i18nKey={'sms-text-length'} values={{ length: values.smsCityInactive?.length ?? 0 }} />}
                    sx={{ width: '100%' }}/>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <Box sx={{ mt: 2, px: 1 }}>
            <Button type={'submit'} variant={'contained'}>{t('Save city')}</Button>
            {!isNaN(cityId) && (<Button type={'button'} color={'error'} variant={'contained'} sx={{ ml: 2 }}
                                        onClick={() => { setShownDeleteDialog(true) }}>{t('Delete')}</Button>)}
          </Box>
        </Grid>
        {!isNaN(cityId) && <>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" mb={5} spacing={2}>
              <Typography variant="h5" gutterBottom>{t('Zones')}</Typography>
              <BaseSearch query={zonesQuery} onQueryChanged={(query) => setZonesQuery(query)}/>
              <Box flexGrow={'1'}/>
              <LoadingButton variant="outlined"
                             loading={exportingSetup}
                      color={'secondary'}
                      onClick={exportCitySetup}
                      startIcon={<Iconify icon="material-symbols:download"/>}>
                {t('Export setup')}
              </LoadingButton>
              <Button variant="contained"
                      component={RouterLink}
                      to={`/cities/${cityId}/zone/create`}
                      startIcon={<Iconify icon="material-symbols:add"/>}>
                {t('New zone')}
              </Button>
            </Stack>
            <BaseTable
              columns={zonesColumns}
              query={zonesQuery}
              forceReloadCounter={zonesForceReloadCounter}
              loadCallback={async (query, page, pageSize) => {
                try {
                  return await api.zone.list(cityId, query, page, pageSize)
                } catch (e) {
                  console.log('Failed to load zones', e)
                  return {
                    data: [],
                    totalCount: 0,
                  }
                }
              }}
            />
          </Grid>
        </>}
        <AlertSimpleDialog
          title={t('Delete zone')}
          message={t('delete-confirm-msg', { name: values.name })}
          open={shownDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />
      </Container>
    </Page>
  )

}