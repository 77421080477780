import { BaseBreadcrumbs }  from '../ui/components/BaseBreadcrumbs'
import React                from 'react'
import {
  Container,
  TableBody, TableCell, TableFooter, TableHead, TableRow,
}                           from '@mui/material'
import { useTranslation }   from 'react-i18next'
import Page                 from '../ui/components/Page'
import { useApi }                          from '../utils/api'
import { fExportPrice, fOperator, fPrice } from '../utils/textutils'
import CommonStatisticsPage                from '../common/CommonStatisticsPage'


const TicketStatisticsPage = () => {

  const api = useApi()
  const { t } = useTranslation()

  return (
    <Page title={t('Ticket statistics')}>
      <Container component="main">
        <BaseBreadcrumbs key={'breadcrumbs-messages'}
                         title={t('Ticket statistics')}
                         items={[ ]}/>
        <CommonStatisticsPage
          fnApiLoadData={api.ticket.statistics}
          pathPrefix={'/statistics/tickets'}
          defaultGroupBy={['city']}
          allowedGroupBy={['city','zone','units','price','hours','deliverystatus','msgtype']}
          exportFileName={'tickets'}
          exportRow={(row, filter) => {
            let obj = {}
            obj[t('City name')] = row.cityName
            if (filter.groupBy.includes('zone')) {
              obj[t('Zone')] = row.zoneName
            }
            if (filter.groupBy.includes('units')) {
              obj[t('StatsUnit')] = row.units ?? 0
              obj[t('StatsUnitSum')] = row.sumUnits ?? 0
            }
            if (filter.groupBy.includes('price')) {
              obj[t('PriceWithVatSingle')] = fExportPrice(row.priceWithVat ?? 0)
            }
            if (filter.groupBy.includes('hours')) {
              obj[t('StatsBillingTime')] = row.hour ? row.hour.toString().padStart(2, '0') + `:00-${(parseInt(row.hour)+1).toString().padStart(2, '0')}:00` : 0
            }
            if (filter.groupBy.includes('deliverystatus')) {
              obj[t('Delivery status')] = row.deliveryStatus ? t(`deliverystatus-${row.deliveryStatus}`) : ''
            }
            if (filter.groupBy.includes('msgtype')) {
              obj[t('Message type')] = row.type ? t(`msgtype-${row.type}`) :''
            }
            obj[t('Count')] = row.count ?? 0
            obj[t('PriceNoVat')] = fExportPrice(row.sumPrice ?? 0)
            obj[t('Vat')] = fExportPrice(row.sumVat ?? 0)
            obj[t('PriceWithVat')] = fExportPrice(row.sumPriceWithVat ?? 0)
            return obj
          }}
        >
          <StatsTableHeader />
          <StatsTableBody />
          <StatsTableFooter />
        </CommonStatisticsPage>
      </Container>
    </Page>)
}

const StatsTableHeader = ({ t, filter, data }) => {

  return <TableHead>
    <TableRow>
      <TableCell>{t('City')}</TableCell>
      {filter.groupBy.includes('zone') && <TableCell>{t('Zone')}</TableCell>}
      {filter.groupBy.includes('units') && <TableCell align={'right'}>{t('StatsUnit')}</TableCell>}
      {filter.groupBy.includes('units') && <TableCell align={'right'}>{t('StatsUnitSum')}</TableCell>}
      {filter.groupBy.includes('price') && <TableCell align={'right'}>{t('PriceWithVatSingle')}&nbsp;€</TableCell>}
      {filter.groupBy.includes('hours') && <TableCell align={'right'}>{t('StatsBillingTime')}</TableCell>}
      {filter.groupBy.includes('deliverystatus') && <TableCell>{t('Delivery status')}</TableCell>}
      {filter.groupBy.includes('msgtype') && <TableCell>{t('Message type')}</TableCell>}
      <TableCell align={'right'}>{t('Count')}</TableCell>
      <TableCell align={'right'}>{t('PriceNoVat')}&nbsp;€</TableCell>
      <TableCell align={'right'}>{t('Vat')}&nbsp;€</TableCell>
      <TableCell align={'right'}>{t('PriceWithVat')}&nbsp;€</TableCell>
    </TableRow>
  </TableHead>
}

const StatsTableBody = ({ t, filter, data }) => {

  return <TableBody>
    {data.map((row, i) => <TableRow key={`row-${i}`}>
      <TableCell>{row.cityName}</TableCell>
      {filter.groupBy.includes('zone') && <TableCell>{row.zoneName}</TableCell>}
      {filter.groupBy.includes('units') && <TableCell align={'right'}>{row.units ?? 0}</TableCell>}
      {filter.groupBy.includes('units') && <TableCell align={'right'}>{row.sumUnits ?? 0}</TableCell>}
      {filter.groupBy.includes('price') && <TableCell align={'right'}>{fPrice(row.priceWithVat ?? 0)}</TableCell>}
      {filter.groupBy.includes('hours') && <TableCell align={'right'}>{row.hour ? row.hour.toString().padStart(2, '0') + `:00-${(parseInt(row.hour)+1).toString().padStart(2, '0')}:00` : 0}</TableCell>}
      {filter.groupBy.includes('deliverystatus') && <TableCell>{row.deliveryStatus ? t(`deliverystatus-${row.deliveryStatus}`) : ''}</TableCell>}
      {filter.groupBy.includes('msgtype') && <TableCell>{row.type ? t(`msgtype-${row.type}`) :''}</TableCell>}
      <TableCell align={'right'}>{row.count ?? 0}</TableCell>
      <TableCell align={'right'}>{fPrice(row.sumPrice ?? 0)}</TableCell>
      <TableCell align={'right'}>{fPrice(row.sumVat ?? 0)}</TableCell>
      <TableCell align={'right'}>{fPrice(row.sumPriceWithVat ?? 0)}</TableCell>
    </TableRow>)}
  </TableBody>
}

const StatsTableFooter = ({ t, filter, data }) => {

  const sum = (prop) => data.map((row) => row[prop] ?? 0).reduce((acc, curr) => acc+curr, 0)
  const countOfEmptyCells = ['city', 'zone', 'units', 'units', 'price', 'hours', 'deliverystatus', 'msgtype'].map(groupBy => filter.groupBy.includes(groupBy)).filter(it => it).length - 1

  return <TableFooter>
    <TableRow>
      <TableCell component={'th'} scope="row">{t('Total')}</TableCell>
      {Array(countOfEmptyCells).fill().map((_, i) => <TableCell key={`cell-${i}`}>&nbsp;</TableCell>)}
      <TableCell align={'right'}>{sum('count')}</TableCell>
      <TableCell align={'right'}>{fPrice(sum('sumPrice'))}</TableCell>
      <TableCell align={'right'}>{fPrice(sum('sumVat'))}</TableCell>
      <TableCell align={'right'}>{fPrice(sum('sumPriceWithVat'))}</TableCell>
    </TableRow>
  </TableFooter>
}

export default TicketStatisticsPage